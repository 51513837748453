import React from 'react';
import { Navbar, Nav, NavDropdown, Container } from 'react-bootstrap';
import { useLocation } from 'react-router-dom'; // Import useLocation hook


const Sidenavigationbar = () => {
  const user = JSON.parse(localStorage.getItem('user')); // Fetching user data from localStorage
  console.log(user);
  const location = useLocation(); // Get the current path
  // Function to check if link is active
  const isActive = (path) => location.pathname === path ? "active" : "";


  return (
    <div className="sidebar">
      <Navbar bg="light" variant="light" expand="lg" className="flex-column">
        
          <Nav className="flex-column">
            {/* Common Links */}
            

            {(user.email.toLowerCase() === "riz@tssprinting.com" || 
              user.email.toLowerCase() === "mussa@tssprinting.com" || 
              user.email.toLowerCase() === "karachi@tssprinting.com") && (
                <>
                <Nav.Link href="/home" className={isActive("/home")}>Home</Nav.Link>
                <Nav.Link href="/orderList" className={isActive("/orderList")}>All Orders</Nav.Link>
                <Nav.Link href="/addShipmentForm" className={isActive("/addShipmentForm")}>Add Garment</Nav.Link>
                <Nav.Link href="/Pullsheet" className={isActive("/Pullsheet")}>Pullsheet</Nav.Link>
              </>
            )}

            {(user.email.toLowerCase() === "riz@tssprinting.com" || 
              user.email.toLowerCase() === "mussa@tssprinting.com" || 
              user.email.toLowerCase() === "karachi@tssprinting.com" || 
              user.email.toLowerCase() === "bob@tssprinting.com") && (
                <>
                <Nav.Link href="/addOrder" className={isActive("/addOrder")}>Add Order</Nav.Link>
              </>
            )}


            {user.email.toLowerCase() === "riz@tssprinting.com" && (
              <>
              <NavDropdown title="Profiles" id="profiles-dropdown">
                <NavDropdown.Item href="/riz" className={isActive("/riz")}>Riz</NavDropdown.Item>
                <NavDropdown.Item href="/bob" className={isActive("/bob")}>Bob Jobs</NavDropdown.Item>
                <NavDropdown.Item href="/mussa" className={isActive("/mussa")}>Mussa</NavDropdown.Item>
                <NavDropdown.Item href="/karachi" className={isActive("/karachi")}>Karachi Team</NavDropdown.Item>
              </NavDropdown>
            </>
            )}

          {user.email.toLowerCase() === "mussa@tssprinting.com" && (
            <Nav.Link href="/mussa" className={isActive("/mussa")}>Mussa</Nav.Link>
          )}

          {user.email.toLowerCase() === "karachi@tssprinting.com" && (
            <Nav.Link href="/karachi" className={isActive("/karachi")}>Karachi Team</Nav.Link>
          )}

          {user.email.toLowerCase() === "bob@tssprinting.com" && (
            <Nav.Link href="/bob" className={isActive("/bob")}>Bob Jobs</Nav.Link>
          )}

            {(user.email.toLowerCase() === "riz@tssprinting.com" || 
              user.email.toLowerCase() === "karachi@tssprinting.com") && (
              <>
                <Nav.Link href="/cancel_return" className={isActive("/cancel_return")}>Cancel & Return</Nav.Link>
                <Nav.Link href="/inventory" className={isActive("/inventory")}>Incoming Boxes</Nav.Link>
                <Nav.Link href="/instockgarments" className={isActive("/instockgarments")}>Instock Garments</Nav.Link>
                
              </>
            )}

          <Nav.Link href="/packing" className={isActive("/packing")}>Packing List</Nav.Link>
          <Nav.Link href="/shipped" className={isActive("/shipped")}>Shipped Orders</Nav.Link>
          <Nav.Link href="/instore" className={isActive("/instore")}>In Store</Nav.Link>
          <Nav.Link href="/completed" className={isActive("/completed")}>Completed Orders</Nav.Link>
          
          <Nav.Link href="/scanHandler" className={isActive("/scanHandler")}>Scan Order</Nav.Link>
          

          </Nav>
        
      </Navbar>
    </div>
  );
};

export default Sidenavigationbar;
