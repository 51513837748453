import React, { useState, useEffect } from 'react';
import { Collapse, Modal, Button, Form } from 'react-bootstrap';
import axios from 'axios';
import moment from 'moment'; // To handle date formatting
import * as XLSX from 'xlsx'; // Import xlsx for Excel file generation
import { BsDownload } from 'react-icons/bs';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { Table } from 'react-bootstrap';


const OrderList = () => {
  const API_URL = process.env.REACT_APP_API_URL;
  const [orders, setOrders] = useState([]);
  const [openOrder, setOpenOrder] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState(null); // To store the order for which "Add num" was clicked
  const [trackingLabel, setTrackingLabel] = useState(''); // To store the tracking label input
  const [showModal2, setShowModal2] = useState(false);
  const [showModal3, setShowModal3] = useState('');
  const [ordernotes, setOrdernotes] = useState("");
  const [search, setSearch] = useState('')
  const [showImageModal, setShowImageModal] = useState(false);
  const [selectedImage, setSelectedImage] = useState('');
  const [sortByDueDate, setSortByDueDate] = useState(false); // Add sorting toggle
  const [updatedOrder, setUpdatedOrder] =useState(null);
  const [field, setField] = useState('');
  const [isAdmin, setIsAdmin] = useState(false);
  const [showSizeModal, setShowSizeModal] = useState(false); // State for Size Modal
  const [orderSizes, setOrderSizes] = useState({});
  const [orderId, setOrderId] = useState(null);
 
  const [address, setAddress] = useState('')
  const [sortByPO,setSortByPO] =useState(false)

  const [garmentDetails, setGarmentDetails] = useState([]);
  const [filteredOrders, setFilteredOrders] = useState([]); // For filtered display
  const [statusFilter, setStatusFilter] = useState(''); // State for the selected filter
  const [garments, setGarments] = useState([]);
  const [showGarmentModal, setShowGarmentModal] = useState(false);

  const [selectedGarments, setSelectedGarments] = useState([]);

  const [showDueDateModal, setShowDueDateModal] = useState(false);
const [selectedOrderId, setSelectedOrderId] = useState(null);
const [newDueDate, setNewDueDate] = useState('');

  

  

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem('user'));
    // Check if user is an admin
    if (user && (user.email.toLowerCase() === "riz@tssprinting.com" || user.email.toLowerCase() === "mussa@tssprinting.com" || user.email.toLowerCase() === "karachi@tssprinting.com")) {
      setIsAdmin(true);
    } else {
      setIsAdmin(false);
    }

  }, []);


  const handleImageClick = (imageUrl) => {
    setSelectedImage(imageUrl);
    setShowImageModal(true);
  };
  

  useEffect(() => {
    const fetchOrders = async () => {
      try {
        const response = await fetch(`${API_URL}/ordersList`);
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        
        setOrders(data);
        setFilteredOrders(response.data);
      } catch (error) {
        console.error('Error fetching orders:', error);
      }
    };

    fetchOrders();
  }, []);

  useEffect(() => {
    // Fetch orders with the search query
    axios.get(`${API_URL}/ordersList?search=${search}`)
      .then((response) => {
        
        setOrders(response.data);
      })
      .catch((error) => {
        console.error('Error fetching orders:', error);
      });
  }, [search]);

  useEffect(() => {
    if (statusFilter) {
      setFilteredOrders(orders.filter(order => order.orderStatus === statusFilter));
    } else {
      setFilteredOrders(orders); // Show all orders when no filter is selected
    }
  }, [statusFilter, orders]);

  const handleStatusFilterChange = (event) => {
    setStatusFilter(event.target.value);
  };

  const resetFilters = () => {
    setStatusFilter('');
    setFilteredOrders(orders);
  };


  useEffect(() => {
    const fetchOrderSizes = async () => {
      
      if (orderId) {
        try {
          const response = await axios.get(`${API_URL}/fetchorders/${orderId}/sizes`);
          setOrderSizes((prevSizes) => ({
            ...prevSizes,
            [orderId]: response.data, // Store sizes for this order
          }));
          console.log(orderSizes)
        } catch (error) {
          console.error('Error fetching sizes:', error);
        }
      }
    };

    if (openOrder) {
      fetchOrderSizes(); // Fetch sizes only when an order is opened
    }
  }, [openOrder]);

  const handleOrderClick = (orderNumber,order_Id) => {
    setOpenOrder(openOrder === orderNumber ? null : orderNumber);
    setOrderId(order_Id)
    fetchGarmentDetails(order_Id)

  };
  

  const handleDeleteGarment = async (garment) => {
    console.log(garment.order_id)
    if (window.confirm('Are you sure you want to delete this garment?')) {
        try {
            // Send request to backend to update shipping status and delete the garment
            const response = await fetch(`${API_URL}/delete-garment`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({garmentId: garment.garment_id, garmentAssignId: garment.id, quantity: garment.quantity }),
            });

            if (response.ok) {
                alert('Garment deleted successfully.');
                // Remove the deleted garment from the list
                setGarmentDetails((prevDetails) => prevDetails.filter((g) => g.id !== garment.id));
            } else {
                alert('Failed to delete the garment.');
            }
        } catch (error) {
            console.error('Error deleting garment:', error);
            alert('An error occurred while deleting the garment.');
        }
    }
};


  const getSelectClass = (status) => {
    switch (status) {
      case 'READY':
        return 'select-ready';
      case 'NEED PAYMENT':
        return 'select-need-payment';
      case 'PENDING':
        return 'select-pending';
      case 'PENDING ARTWORK':
        return 'select-pending-artwork';
      case 'APPROVED':
        return 'select-approved';
      case 'HARDDATE':
        return 'select-harddate';
      case 'PENDING APPROVAL':
        return 'select-pending-approval';
      default:
        return '';
    }
  };

  const updateOrderStatusInDatabase = async (e, id) => {
    const status = e.target.value;

    try {
        const response = await fetch(`${API_URL}/updateOrderStatus/${id}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ status }),
        });

        if (!response.ok) {
            throw new Error('Failed to update order status');
        }

        const updatedOrder = await response.json();
        console.log('Order status updated successfully:', updatedOrder);

        if (status === 'CANCEL') {
            console.log('Deleting all garments for cancelled order...');
            await deleteAllGarments(id); // Ensure this completes before proceeding
        }

        setOrders((prevOrders) =>
            prevOrders
                .map((order) =>
                    order.id === id ? { ...order, orderStatus: status } : order
                )
                .filter(
                    (order) =>
                        order.orderStatus !== 'READY' &&
                        order.orderStatus !== 'HARDDATE' &&
                        order.orderStatus !== 'CANCEL'
                )
        );
    } catch (error) {
        console.error('Error updating order status:', error);
    }
};


const deleteAllGarments = async (orderId) => {
  try {
      // Step 1: Fetch garments linked to the order
      const response = await axios.get(`${API_URL}/orderGarments`, {
          params: { orderId },
      });

      if (response.status !== 200) {
          console.error('Failed to fetch garments for order:', orderId);
          return Promise.reject('Failed to fetch garments');
      }

      const garments = response.data;
      console.log(`Deleting ${garments.length} garments for order ${orderId}`);

      // Step 2: Loop through garments and delete each one
      const deletionPromises = garments.map(async (garment) => {
          try {
              await axios.post(`${API_URL}/delete-garment`, {
                  garmentId: garment.garment_id,
                  garmentAssignId: garment.id,
                  quantity: garment.quantity,
              });
              console.log(`Garment ${garment.id} deleted successfully.`);
          } catch (deleteError) {
              console.error(`Failed to delete garment ${garment.id}:`, deleteError);
          }
      });

      // Wait for all deletions to complete before resolving
      await Promise.all(deletionPromises);
      console.log(`All garments deleted for order ${orderId}`);

      return Promise.resolve(); // Ensure it resolves when complete
  } catch (error) {
      console.error('Error fetching garments before deletion:', error);
      return Promise.reject(error);
  }
};

  
  const handleUpdateOrder = async () => {
    if (!orderId || !field || updatedOrder === undefined || updatedOrder === null) {
      alert('Please provide all necessary details before updating the order.');
      return;
    }
  
    try {
      // Make the API call to update the selected order's specific field
      const response = await axios.put(`${API_URL}/updateOrder/${orderId}`, { [field]: updatedOrder });
  
      if (response.status === 200) {
        alert(response.data.message || 'Order updated successfully');
  
        // Update the local state with the new order data
        setOrders((prevOrders) =>
          prevOrders.map((order) => {
            if (order.id === orderId) {
              // Handle notes by appending the new note to the existing notes
              if (field === 'notes') {
                return { ...order, notes: `${order.notes ? order.notes + '\n' : ''}${updatedOrder}` };
              }
              // Handle other fields normally
              return { ...order, [field]: updatedOrder };
            }
            return order;
          })
        );

        setShowModal3(false);
      setOrderId('');
      setUpdatedOrder(''); 
  
        // Reset the modal state and clear inputs after successful update
        
      } else {
        alert('Failed to update the order. Please try again.');
      }
    } catch (error) {
      console.error('Error updating order:', error);
      alert('An error occurred while updating the order. Please try again.');
    }
  };

  // Function to show modal and set the order and field being edited
  const handleOrder = (id, field) => {
    setShowModal3(true);
    setOrderId(id);
    setField(field); // Track the field being updated
  };

  const deleteOrder = async (id) => {
    const confirmDelete = window.confirm('Are you sure you want to delete this order?');
    if (confirmDelete) {
      try {
        const response = await axios.delete(`${API_URL}/deleteorder/${id}`);
        if (response.status === 200) {
          setOrders(orders.filter(order => order.id !== id));
          alert('Order deleted successfully.');
        }
      } catch (error) {
        console.error('Error deleting order:', error);
        alert('Failed to delete the order.');
      }
    }
  };

  const toggleSortByDueDate = () => {
    setSortByDueDate(!sortByDueDate);
    const sortedOrders = [...orders].sort((a, b) => {
      const dateA = new Date(a.dueDate);
      const dateB = new Date(b.dueDate);
      return sortByDueDate ? dateA - dateB : dateB - dateA;
    });
    setOrders(sortedOrders);
  };
  const toggleSortByPO = () => {
    setSortByPO(currentState => {
        const newState = !currentState;
        
        // Fetch sorted data from the backend
        axios.get(`${API_URL}/orders/sorted?sortByPO=${newState}`)
            .then(response => {
                setOrders(response.data);
            })
            .catch(error => {
                console.error('Error fetching sorted orders:', error);
            });

        return newState;
    });
};

  

  const downloadPDF = () => {
    // Prepare data in the format for the table
    const tableData = orders.map((order) => [
      order.id, // Adding the order ID
      order.orderNumber,
      order.clientName,
      
      order.garmentPO,
      
      order.garmentDetails ? 
      order.garmentDetails.split('\n').join(', ') : // Convert to a comma-separated string
      'No Garment details',
      order.jobType
      
    ]);
  
    // Define table columns (headers)
    const tableColumns = [
      'ID', 'Order Number', 'Client Name',  'Garment PO', 'Garment Details','JobType'
    ];
  
    // Initialize jsPDF instance
    const doc = new jsPDF('landscape'); // Use 'landscape' for wide tables
  
    // Set title for the document
    doc.text('All Orders', 14, 22);
  
    // Add table with autoTable plugin
    doc.autoTable({
      head: [tableColumns], // Table headers
      body: tableData,      // Table rows (order data)
      startY: 30,           // Position of the table
      theme: 'grid',        // You can also use 'striped', 'plain', etc.
      styles: {
        fontSize: 8,        // Adjust font size to fit more content
      },
      headStyles: {
        fillColor: [22, 160, 133], // Customize header background color
        textColor: 255,           // White text in headers
      }
    });
  
    // Save the PDF with a dynamic filename
    doc.save(`All_orders_${moment().format('YYYY-MM-DD')}.pdf`);
  };


  const handleFileUpload = async (id) => {
    try {
        const fileInput = document.createElement('input');
        fileInput.type = 'file';
        fileInput.multiple = true; // Allow multiple files to be selected
        fileInput.onchange = async (event) => {
            const files = Array.from(event.target.files);
            if (files.length === 0) return;

            const formData = new FormData();
            files.forEach((file) => formData.append('files', file)); // Append each file

            const response = await fetch(`${API_URL}/api/orders/${id}/files`, {
                method: 'POST',
                body: formData,
            });

            if (response.ok) {
                const uploadedFiles = await response.json(); // Assuming it returns an array of file URLs
                const fileUrls = uploadedFiles.fileUrls || [];

                if (fileUrls.length === 0) {
                    console.error('File URLs are undefined or empty from backend.');
                    return;
                }

                // Update state to include new file details
                setOrders((prevOrders) =>
                    prevOrders.map((order) =>
                        order.id === id
                            ? { 
                                ...order, 
                                files: [...(order.files || []), ...fileUrls.map((url) => ({ fileUrl: url }))] 
                              }
                            : order
                    )
                );
                console.log(orders);
            } else {
                console.error('Failed to upload files:', response.statusText);
            }
        };
        fileInput.click();
    } catch (error) {
        console.error('Error during file upload:', error);
    }
};


const handlePDFPreview = (fileUrl) => {
  if (fileUrl.match(/\.pdf$/i)) {
    const googleViewerUrl = `http://docs.google.com/viewer?url=${encodeURIComponent(fileUrl)}&embedded=true`;
    window.open(googleViewerUrl, '_blank', 'noopener,noreferrer');
  } else {
    alert('The selected file is not a PDF.');
  }
};







const handleDeleteFile = async (file ,index, id) => {
  
  setOrders((prevOrders) =>
    prevOrders.map((order) =>
      order.id === id
        ? { ...order, files: order.files.filter((f)=>f.fileUrl !== file.fileUrl) }
        : order
    )
  );
  try {
    const response = await fetch(`${API_URL}/api/orders/${id}/files`, {
      method: 'DELETE',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ fileUrl: file.fileUrl }),
    });

    if (response.ok) {
      setOrders((prevOrders) =>
        prevOrders.map((order) =>
          order.id === id
            ? { ...order, files: order.files.filter((f)=>f.fileUrl !== file.fileUrl) }
            : order
        )
      );
    } else {
      const errorText = await response.text();
      console.error('Failed to delete file:', errorText);
    }
  } catch (error) {
    console.error('Error during file deletion:', error);
  }
};

function cleanFileName(url) {
  // Decode URI components to handle encoded characters like %20 for space
  let decodedUrl = decodeURIComponent(url);
  
  // Extract the filename from the URL if necessary
  let fileName = decodedUrl.split('/').pop(); // Gets the last part after the last slash
  
  // Optional: Remove any unwanted parts from the filename, such as timestamps or identifiers
  // For example, if filenames contain a pattern like '1730410751349-', you might want to remove it:
  fileName = fileName.replace(/^\d+-/, ''); // Removes leading digits followed by a dash
  
  // More cleaning can be applied here depending on the specific filename patterns you have
  
  return fileName;
}

const fetchGarments = async (poNumber) => {
  console.log(poNumber)
  try {
      const response = await axios.get(`${API_URL}/garments?po=${poNumber}`);

      setGarments(prevGarments => [...prevGarments, ...response.data]);
      console.log(garments)
  } catch (error) {
      console.error('Error fetching garments:', error);
  }
}
const handleAddGarmentsClick = (order) => {
  setOrderId(order.id);
  setGarments([]);
  const garmentPOs = order.garmentPO.split(',');

  garmentPOs.forEach(garmentPO => {
      console.log(garmentPO)
      fetchGarments(garmentPO.trim()); // Use trim() to remove any extra whitespace
  });

  setShowGarmentModal(true);
};



const handleSelectGarment = (garmentId,po, style, description, color, size,available_qty, quantity) => {
    console.log(quantity)
    setSelectedGarments((prev) => {
        // Check if the garment already exists in the selection
        const existingGarment = prev.find((g) => g.garmentId === garmentId);
        if (existingGarment) {
            // Update quantity if the garment is already selected

            return prev.map((g) =>
                g.garmentId === garmentId ? { ...g, quantity } : g
            );
        }

        // Add new garment to selection
        return [
            ...prev,
            { garmentId,po, style, description, color, size, quantity },
        ];
        
    });
    
};

const handleAssignGarments = async () => {
  console.log(selectedGarments);
  if (selectedGarments.length === 0) {
      alert('Please select at least one garment to assign.');
      return;
  }

  try {
      const response = await axios.post(`${API_URL}/assignGarments`, {
          orderId,
          garments: selectedGarments,
      });

      if (response.status === 200) {
          alert('Garments assigned successfully.');

          // **Update the UI immediately**
          fetchGarmentDetails(orderId)

          // Close the modal and clear selected garments
          setShowGarmentModal(false);
          setSelectedGarments([]);

      } else if (response.status === 400) {
          alert(response.data.message);
      }

  } catch (error) {
      console.error('Error assigning garments:', error);
      alert('An error occurred while assigning garments.');
  }
};


const fetchGarmentDetails = async (orderId) => {
  try {
      const response = await axios.get(`${API_URL}/orderGarments`, {
          params: { orderId },
      });
      setGarmentDetails(response.data);
  } catch (error) {
      console.error('Error fetching garment details:', error);
  }
};

const handleEditDueDate = (id, dueDate) => {
  setSelectedOrderId(id);
  const formattedDate = moment(dueDate).utcOffset(0, true).format('YYYY-MM-DD');
  setNewDueDate(formattedDate);
  console.log(newDueDate)
  setShowDueDateModal(true);
};

const updateDueDate = async () => {
  if (!selectedOrderId || !newDueDate) {
    alert('Please select a valid due date.');
    return;
  }

  try {
    const response = await axios.put(`${API_URL}/updateOrderDueDate/${selectedOrderId}`, {
      dueDate: newDueDate,
    });

    if (response.status === 200) {
      alert('Due date updated successfully.');

      // Update state to reflect change immediately
      setOrders((prevOrders) =>
        prevOrders.map((order) =>
          order.id === selectedOrderId ? { ...order, dueDate: newDueDate } : order
        )
      );

      setShowDueDateModal(false); // Close the modal
    } else {
      alert('Failed to update due date.');
    }
  } catch (error) {
    console.error('Error updating due date:', error);
    alert('An error occurred while updating the due date.');
  }
};



  return (
    <div className="container" style={{ marginLeft: 250, paddingTop: 20,marginBottom:70 }}>

      <h2>All Orders</h2>
      <div className="row">
        <div className="col-md-3">
          <input
            type="text"
            className="form-control"
            style={{ width: '300px' }} 
            placeholder="Search orders... 🔍"
            onChange={(e) => setSearch(e.target.value)}
          />
          </div>

          <div className="col-md-3">
          <select
            className="form-control"
            value={statusFilter}
            onChange={handleStatusFilterChange}
          >
            <option value="">Filter by Status</option>
            <option value="READY">READY</option>
            <option value="NEED PAYMENT">NEED PAYMENT</option>
            <option value="PENDING">PENDING</option>
            <option value="PENDING ARTWORK">PENDING ARTWORK</option>
            <option value="APPROVED">APPROVED</option>
            <option value="HARDDATE">HARDDATE</option>
            <option value="PENDING APPROVAL">PENDING APPROVAL</option>
            <option value="CANCEL">CANCEL</option>
          </select>
           </div>


          <div className="col-md-2">
            <Button variant="primary" onClick={downloadPDF}>
              <BsDownload style={{ marginRight: '5px' }} /> {/* Add download icon */}
              Download
            </Button>
            
            </div>
            <div className="col-md-2">
          <Button variant="secondary" onClick={resetFilters}>
            Reset Filters
          </Button>
        </div>
        <h4>Total no of Orders : {orders.length}</h4>
      </div>
      
      <table className="table table-striped table-hover">
        <thead className="thead-dark table-header">
          <tr>
            <th scope="col">Order Number</th>
            <th scope="col" className="wide-col">Client Name</th>
            <th scope="col" className="wide-col">Client Phone</th>
            <th scope="col" className="extra-wide-col">Client Gmail</th>
            <th scope="col" className="wide-col">Order Status</th>
            <th scope="col" className="wide-col">Order Method</th>
            <th scope="col" className="wide-col">Job Type</th>
            <th scope="col" className="wide-col">
              Due Date
              <i
                className={`bi bi-sort-${sortByDueDate ? 'down' : 'up'} sort-icon`}
                onClick={toggleSortByDueDate}
              ></i>
            </th>
            
            <th scope="col" className="wide-col">
              Garment PO
              <i
                className={`bi bi-sort-${sortByPO ? 'down' : 'up'} sort-icon`}
                onClick={toggleSortByPO}
              ></i>
            </th>
            <th scope="col" className="extra-wide-col">Tracking Number</th>
          </tr>
        </thead>
        <tbody>
          {filteredOrders?.map((order, index) => {
            
            return (
              <React.Fragment key={index}>
                <tr  
                style={{
                  backgroundColor: order.jobType.trim() === 'DTG+EMB' ? 'yellow' : 'transparent'
                }}  onClick={() => handleOrderClick(order.orderNumber,order.id)}>
                  <td className="order-cell" >
                    
                    {order.orderNumber}
                    <>
                    {isAdmin ? (<i 
                          className="bi bi-pencil" 
                          style={{ cursor: 'pointer', marginLeft: '5px' }} 
                          onClick={() => handleOrder(order.id,"orderNumber")}
                        ></i>) : ''}
                    </>
                  </td>
                  <td>{order.clientName}
                    <>
                    {isAdmin ? (<i 
                          className="bi bi-pencil" 
                          style={{ cursor: 'pointer', marginLeft: '5px' }} 
                          onClick={() => handleOrder(order.id,"clientName")}
                        ></i>) : ''}
                    </>
                  </td>
                  <td>{order.clientPhone}
                    <>
                    {isAdmin ? (<i 
                          className="bi bi-pencil" 
                          style={{ cursor: 'pointer', marginLeft: '5px' }} 
                          onClick={() => handleOrder(order.id,"clientPhone")}
                        ></i>) : ''}
                    </>
                  </td>
                  <td>{order.clientgmail}
                    <>
                    {isAdmin ? (<i 
                          className="bi bi-pencil" 
                          style={{ cursor: 'pointer', marginLeft: '5px' }} 
                          onClick={() => handleOrder(order.id,"clientgmail")}
                        ></i>) : ''}
                    </>
                  </td>
                  <td>
                    <select
                      value={order.orderStatus}
                      onChange={(e) => updateOrderStatusInDatabase(e, order.id)}
                      className={getSelectClass(order.orderStatus)}
                    >
                      <option value="READY">READY</option>
                      <option value="NEED PAYMENT">NEED PAYMENT</option>
                      <option value="PENDING">PENDING</option>
                      <option value="PENDING ARTWORK">PENDING ARTWORK</option>
                      <option value="APPROVED">APPROVED</option>
                      <option value="HARDDATE">HARDDATE</option>
                      <option value="PENDING APPROVAL">PENDING APPROVAL</option>
                      {isAdmin ? (<option value="CANCEL">Cancel</option>) : ''}
                      
                    </select>
                  </td>

                  <td>{order.orderMethod}</td>
                  <td>{order.jobType}</td>
                  
                  

                  <td>
                    {moment.utc(order.dueDate).format('MM/DD/YYYY')}
                    {isAdmin && (
                      <i
                        className="bi bi-pencil"
                        style={{ cursor: 'pointer', marginLeft: '5px', color: '#007bff' }}
                        onClick={() => handleEditDueDate(order.id, order.dueDate)}
                      ></i>
                    )}
                  </td>
                  

                  <td>{order.garmentPO}
                  <>
                    {isAdmin ? (<i 
                          className="bi bi-pencil" 
                          style={{ cursor: 'pointer', marginLeft: '5px' }} 
                          onClick={() => handleOrder(order.id,"garmentPO")}
                        ></i>) : ''}
                  </>
                  </td>
                  
                  
                  <td>
                {order.trackingLabel ? (
                      <>
                        {order.trackingLabel}
                        <>
                          {isAdmin && (
                            <i
                              className="bi bi-pencil"
                              style={{ cursor: 'pointer', marginLeft: '5px' }}
                              onClick={() => handleOrder(order.id, "trackingLabel")}
                            ></i>
                          )}
                        </>
                        {/* Copy icon */}
                        {/* <i
                          className="bi bi-clipboard"
                          style={{ cursor: 'pointer', marginLeft: '10px' }}
                          onClick={() => {
                            navigator.clipboard.writeText(order.trackingLabel);
                            alert('Copied to clipboard!'); // Optional feedback for the user
                          }}
                        ></i> */}
                      </>
                    ) : (
                      <button
                        className="btn btn-primary"
                        style={{ cursor: 'pointer' }}
                        onClick={() => handleOrder(order.id, "trackingLabel")}
                      >
                        Add num
                      </button>
                    )}
                  </td>

                  {/* <td>
                    <Button
                      variant="primary"
                      onClick={() => handleSizeModalShow(order.orderNumber,order.shippingAddress)}
                    >
                      Add Size
                    </Button>
                  </td> */}

                  <td>
                    <i
                      className="bi bi-trash"
                      style={{ cursor: 'pointer', color: 'red' }}
                      onClick={() => deleteOrder(order.id)}
                    ></i>
                  </td>

                </tr>
                <tr>
                  <td colSpan="12">
                    <Collapse in={openOrder === order.orderNumber}>
                      <div>
                      <div>
                            <p><strong>Garment Details:</strong></p>
                            {isAdmin ? <Button onClick={() => handleAddGarmentsClick(order)}>Add Garments</Button> :''}
                            <div style={{ paddingLeft: '20px' }}>
                                  
                                      <p>{order.garmentDetails}</p>
                                  
                                      {garmentDetails.length > 0 ? (
                                        <div style={{ maxWidth: '800px', border: '1px solid #dee2e6', borderRadius: '8px', overflow: 'hidden', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }}>
                                          <table className="table table-hover" style={{ margin: 0 }}>
                                              <thead style={{ background: 'linear-gradient(to right, #333, #555)', color: 'white', textAlign: 'center' }}>
                                                  <tr>
                                                      <th style={{ padding: '10px', fontWeight: 'bold' }}>Style</th>
                                                      <th style={{ padding: '10px', fontWeight: 'bold' }}>PO</th>
                                                      <th style={{ padding: '10px', fontWeight: 'bold' }}>Description</th>
                                                      <th style={{ padding: '10px', fontWeight: 'bold' }}>Color</th>
                                                      <th style={{ padding: '10px', fontWeight: 'bold' }}>Size</th>
                                                      <th style={{ padding: '10px', fontWeight: 'bold' }}>Quantity</th>
                                                  </tr>
                                              </thead>
                                              <tbody>
                                                  {garmentDetails.map((detail, index) => (
                                                      <tr
                                                          key={index}
                                                          style={{
                                                              backgroundColor: index % 2 === 0 ? '#f8f9fa' : '#ffffff',
                                                              transition: 'background-color 0.3s ease',
                                                          }}
                                                          onMouseEnter={(e) => (e.currentTarget.style.backgroundColor = '#e9ecef')}
                                                          onMouseLeave={(e) => (e.currentTarget.style.backgroundColor = index % 2 === 0 ? '#f8f9fa' : '#ffffff')}
                                                      >
                                                          <td style={{ padding: '8px', textAlign: 'center', width: '10%' }}>{detail.style}</td>
                                                          <td style={{ padding: '8px', textAlign: 'center', width: '10%' }}>{detail.po}</td>
                                                          <td style={{ padding: '8px', width: '40%' }}>{detail.description}</td>
                                                          <td style={{ padding: '8px', textAlign: 'center', width: '15%' }}>{detail.color}</td>
                                                          <td style={{ padding: '8px', textAlign: 'center', width: '10%' }}>{detail.size}</td>
                                                          <td style={{ padding: '8px', textAlign: 'center', width: '10%' }}>{detail.quantity}</td>
                                                          {isAdmin && (
                                                              <td style={{ padding: '8px', textAlign: 'center', width: '10%' }}>
                                                              <button
                                                                  style={{
                                                                      backgroundColor: '#dc3545',
                                                                      color: 'white',
                                                                      border: 'none',
                                                                      borderRadius: '5px',
                                                                      padding: '5px 10px',
                                                                      cursor: 'pointer',
                                                                  }}
                                                                  onClick={() => handleDeleteGarment(detail)}
                                                              >
                                                                  Delete
                                                              </button>
                                                          </td>
                                                          )}
                                                      </tr>
                                                  ))}
                                              </tbody>
                                          </table>
                                      </div>

                                    
                                      ) : (
                                        <></>
                                      )
                                  }
                              </div>


                            {isAdmin && (
                              <i
                                className="bi bi-pencil"
                                style={{ cursor: 'pointer', marginLeft: '5px' }}
                                onClick={() => handleOrder(order.id, "garmentDetails")}
                              ></i>
                            )}
                          </div>
                          <p><strong>Notes:</strong> 
                          <div style={{ paddingLeft: '20px' }}>
                              {order.notes ? (
                                <ul>
                                  {order.notes.split('\n').map((detail, index) => (
                                    <li key={index}>{detail}</li> // Display each detail as a list item
                                  ))}
                                </ul>
                              ) : (
                                <p>No notes</p>
                              )}
                          </div>
                        <i 
                          className="bi bi-pencil" 
                          style={{ cursor: 'pointer', marginLeft: '5px' }} 
                          onClick={() => handleOrder(order.id,"notes")}
                        ></i>
                        </p>
                        <p><strong>Team:</strong> {order.team}</p>
                        
                        
                        <p><strong>Shipping Address:</strong> {order.shippingAddress}
                        <>
                          {isAdmin ? (<i 
                                className="bi bi-pencil" 
                                style={{ cursor: 'pointer', marginLeft: '5px' }} 
                                onClick={() => handleOrder(order.id,"shippingAddress")}
                              ></i>) : ''}
                        </>
                        </p>
                        

                       
                        <p><strong>Files Uploaded:</strong></p>
                        {isAdmin ? <a
                              
                              onClick={() => handleFileUpload(order.id)} // Define this function to handle file uploads
                              style={{
                                display: 'inline-flex',
                                alignItems: 'center',
                                textDecoration: 'none',
                                color: '#007bff',
                                marginBottom: '15px',
                                cursor: 'pointer',
                              }}
                            >
                              <i className="bi bi-plus-circle" style={{ marginRight: '5px', fontSize: '20px' }}></i> Add File
                            </a> : ''}
                            
                                                    <ul style={{ display: 'flex', flexWrap: 'wrap', listStyleType: 'none', padding: 0 }}>
                          {order.files && order.files.length > 0 ? (
                            order.files.filter((file) => file.fileUrl)
                            .map((file, idx) => {
                              const fileUrl = String(file.fileUrl); // Convert fileUrl to a string
                              const fileName = cleanFileName(fileUrl); // Clean the filename for display

                              return (
                                <li key={idx} style={{
                                  margin: '5px',
                                  width: 'calc(100% / 7 - 10px)', 
                                  display: 'flex',
                                  flexDirection: 'column',
                                  alignItems: 'center',
                                  justifyContent: 'center'
                                }}>
                                  {/* Display different content based on file type */}
                                  {fileUrl.match(/\.(jpeg|jpg|gif|png)$/i) ? (
                                    <>
                                      <img
                                        src={fileUrl}
                                        alt={`file-${idx}`}
                                        style={{ width: '100%', height: 'auto', maxWidth: '100px', marginBottom: '5px' }}
                                        onClick={() => handleImageClick(fileUrl)}
                                      />
                                      <div>
                                        <a href={fileUrl} download={fileName}>
                                          {fileName}
                                        </a>
                                        <button
                                          onClick={() => handleDeleteFile(file, idx, order.id)}
                                          style={{
                                            border: 'none',
                                            background: 'transparent',
                                            color: '#d9534f',
                                            cursor: 'pointer'
                                          }}
                                        >
                                          <i className="bi bi-trash"></i>
                                        </button>
                                        
                                      </div>
                                    </>
                                  ) : (
                                    <>
                                      <div style={{
                                          display: 'flex', 
                                          alignItems: 'center', 
                                          justifyContent: 'center', 
                                          width: '100px', 
                                          height: '100px', 
                                          border: '1px solid #ddd', 
                                          borderRadius: '5px', 
                                          backgroundColor: '#f8f9fa'
                                        }}
                                      >
                                        <i className="bi bi-file-earmark-text" style={{ fontSize: '24px' }}></i>
                                      </div>
                                      <div>
                                        <a href={fileUrl} download={fileName}>
                                          {fileName}
                                        </a>
                                        <button
                                          onClick={() => handleDeleteFile(file, idx, order.id)}
                                          style={{
                                            border: 'none',
                                            background: 'transparent',
                                            color: '#d9534f',
                                            cursor: 'pointer'
                                          }}
                                        >
                                          <i className="bi bi-trash"></i>
                                        </button>
                                        {/* Preview Button */}
                                        <button
                                          onClick={() => handlePDFPreview(fileUrl)}
                                          style={{
                                            border: 'none',
                                            background: 'transparent',
                                            color: '#007bff',
                                            cursor: 'pointer',
                                            marginLeft: '10px'
                                          }}
                                        >
                                          <i className="bi bi-eye"></i> Preview
                                        </button>
                                      </div>
                                    </>
                                  )}
                                </li>
                              );
                            })
                          ) : (
                            <li style={{ width: '100%', textAlign: 'center' }}>No files uploaded.</li>
                          )}
                        </ul>




                      </div>
                    </Collapse>
                  </td>
                </tr>
              </React.Fragment>
            );
          })}
        </tbody>
      </table>

      <Modal show={showModal3} onHide={() => setShowModal3(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Enter {field}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group>
            <Form.Label>{field}</Form.Label>
            {/* Render textarea for notes or garmentDetails, otherwise render input */}
            {field === 'notes' || field === 'garmentDetails' ? (
              <Form.Control
                as="textarea"
                rows={4} // Adjust the number of rows as needed
                value={updatedOrder}
                onChange={(e) => setUpdatedOrder(e.target.value)}
              />
            ) : (
              <Form.Control
                type="text"
                value={updatedOrder}
                onChange={(e) => setUpdatedOrder(e.target.value)}
              />
            )}
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal3(false)}>
            Cancel
          </Button>
          <Button variant="primary" onClick={() => handleUpdateOrder()}>
            Submit
          </Button>
        </Modal.Footer>
      </Modal>


      
      {/* Modal for displaying enlarged image */}
      <Modal show={showImageModal} onHide={() => setShowImageModal(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title>Image Preview</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <img src={selectedImage} alt="Enlarged" style={{ width: '100%', height: 'auto' }} />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowImageModal(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Garment Modal */}
                <Modal
              show={showGarmentModal}
              onHide={() => setShowGarmentModal(false)}
              centered
              size="xl" // Enlarges the modal
          >
              <Modal.Header closeButton>
                  <Modal.Title>Select Garments</Modal.Title>
              </Modal.Header>
              <Modal.Body style={{ maxHeight: '70vh', overflowY: 'auto', padding: '20px' }}>
                  <Table striped bordered hover responsive>
                      <thead className="table-dark">
                          <tr>
                              <th>Garment ID</th>
                              <th>PO</th>
                              <th>Style</th>
                              <th>Garment Desc</th>
                              <th>Color</th>
                              <th>Size</th>
                              <th>Available Quantity</th>
                              <th>Checked In</th>
                              <th>Assign Quantity</th>
                          </tr>
                      </thead>
                      <tbody>
                          {garments.map((garment) => (
                              <tr key={garment.id}>
                                  <td>{garment.id}</td>
                                  <td>{garment.customer_po}</td>
                                  <td>{garment.style}</td>
                                  <td>{garment.description}</td>
                                  <td>{garment.color}</td>
                                  <td>{garment.size}</td>
                                  
                                  <td>{garment.qty}</td>
                                  <td>{garment.verified ? 'YES' :'NO'}</td>
                                  <td>
                                      <Form.Control
                                          type="number"
                                          min="1"
                                          max={garment.qty}
                                          onChange={(e) =>
                                            handleSelectGarment(
                                                garment.id,
                                                garment.customer_po,
                                                garment.style,
                                                garment.description,
                                                garment.color,
                                                garment.size,
                                                garment.qty,                                                
                                                parseInt(e.target.value, 10)
                                            )
                                        }
                                          style={{
                                              width: '100%',
                                              padding: '5px 10px',
                                              borderRadius: '4px',
                                              border: '1px solid #ced4da',
                                          }}
                                      />
                                  </td>
                              </tr>
                          ))}
                      </tbody>
                  </Table>
              </Modal.Body>
              <Modal.Footer>
                  <Button variant="secondary" 
                  onClick={() => setShowGarmentModal(false)}
                  >
                      Cancel
                  </Button>
                  <Button variant="primary"
                   onClick={handleAssignGarments}
                   >
                      Assign Garments
                  </Button>
              </Modal.Footer>
          </Modal>
          <Modal show={showDueDateModal} onHide={() => setShowDueDateModal(false)} centered>
            <Modal.Header closeButton>
              <Modal.Title>Edit Due Date</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form.Group>
                <Form.Label>New Due Date</Form.Label>
                <Form.Control
                  type="date"
                  value={newDueDate}
                  onChange={(e) => setNewDueDate(e.target.value)}
                />
              </Form.Group>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={() => setShowDueDateModal(false)}>
                Cancel
              </Button>
              <Button variant="primary" onClick={updateDueDate}>
                Update
              </Button>
            </Modal.Footer>
          </Modal>



    </div>
  );
};

export default OrderList;
