import React, { useState, useEffect } from 'react';
import { Collapse, Modal, Button, Form } from 'react-bootstrap';
import axios from 'axios';
import moment from 'moment'; // To handle date formatting
import * as XLSX from 'xlsx'; // Import xlsx for Excel file generation
import { BsDownload } from 'react-icons/bs';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { Table } from 'react-bootstrap';


const Bob = () => {
  const [orders, setOrders] = useState([]);
  const [openOrder, setOpenOrder] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState(null); // To store the order for which "Add num" was clicked
  const [trackingLabel, setTrackingLabel] = useState(''); // To store the tracking label input
  const [showModal2, setShowModal2] = useState(false);
  const [showModal3, setShowModal3] = useState('');
  const [ordernotes, setOrdernotes] = useState("");
  const [search, setSearch] = useState('')
  const [showImageModal, setShowImageModal] = useState(false);
  const [selectedImage, setSelectedImage] = useState('');
  const [sortByDueDate, setSortByDueDate] = useState(false); // Add sorting toggle
  const [updatedOrder, setUpdatedOrder] =useState(null);
  const [field, setField] = useState('');
  const [isAdmin, setIsAdmin] = useState(false);
  const [showSizeModal, setShowSizeModal] = useState(false); // State for Size Modal
  const [orderSizes, setOrderSizes] = useState({});
  const [orderId, setOrderId] = useState(null);
 
  const [address, setAddress] = useState('')
  const API_URL = process.env.REACT_APP_API_URL;
  const user = JSON.parse(localStorage.getItem('user'));
  const [garments, setGarments] = useState([]);
    const [showGarmentModal, setShowGarmentModal] = useState(false);
  
    const [selectedGarments, setSelectedGarments] = useState([]);

  const [sizeData, setSizeData] = useState({
    category: 'Adult', // Default category
    description: '',
    color: '',
    xs: 0,
    s: 0,
    m: 0,
    l: 0,
    xl: 0,
    xxl: 0,
    xxxl:0,
    xxxxl:0,
    xxxxxl:0,
  });

  const handleSizeInputChange = (event) => {
    const { name, value } = event.target;
    setSizeData((prevData) => ({
      ...prevData,
      [name]: name === 'xs' || name === 's' || name === 'm' || name === 'l' || name === 'xl' || name === 'xxl' || name === 'xxxl' || name === 'xxxxl' || name === 'xxxxxl'
        ? parseInt(value) || 0  // Convert to number or default to 0 if empty
        : value
    }));
    console.log(sizeData);
  };

  const handleSizeModalShow = (orderNumber,shippingAddress) => {
    setSelectedOrder(orderNumber); // Set the selected order number
    setAddress(shippingAddress)
    setShowSizeModal(true);
  };

  
  const handleSizeFormSubmit = async () => {
    try {
      // Fetch order ID using the selected order and address
      const response = await fetch(
        `${API_URL}/getOrderId?orderNumber=${selectedOrder}&shippingAddress=${address}`
      );
      
      if (!response.ok) {
        throw new Error('Order not found');
      }
  
      const data = await response.json();  // Correctly parse the response JSON
   
  
      if (!data || !data.order_id) {  // Check if order_id exists in the response
        alert('Order not found');
        return;
      }
  
      // Ensure sizeData contains valid numbers for sizes
      const formattedSizeData = {
        ...sizeData,
        xs: parseInt(sizeData.xs) || 0,
        s: parseInt(sizeData.s) || 0,
        m: parseInt(sizeData.m) || 0,
        l: parseInt(sizeData.l) || 0,
        xl: parseInt(sizeData.xl) || 0,
        xxl: parseInt(sizeData.xxl) || 0,
        xxxl: parseInt(sizeData.xxxl) || 0,
        xxxxl: parseInt(sizeData.xxxxl) || 0,
        xxxxxl: parseInt(sizeData.xxxxxl) || 0,
      };
  
      // POST the size data to the server using the retrieved order_id
      const sizeResponse = await axios.post(
        `${API_URL}/orders/${data.order_id}/sizes`,
        formattedSizeData
      );
  
      console.log('Size data added:', sizeResponse.data);
      setShowSizeModal(false); // Close the modal after successful submission
    } catch (error) {
      console.error('Error adding size data:', error);
      alert(error.message); // Display error to the user
    }
  };
  
  

  

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem('user'));
    // Check if user is an admin
    if (user.email.toLowerCase() === "riz@tssprinting.com" || user.email.toLowerCase() === "bob@tssprinting.com") {
      setIsAdmin(true);
    } else {
      setIsAdmin(false);
    }
  }, []);


  const handleImageClick = (imageUrl) => {
    setSelectedImage(imageUrl);
    setShowImageModal(true);
  };
  

  useEffect(() => {
    const fetchOrders = async () => {
      try {
        const response = await fetch(`${API_URL}/bobJobs`);
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        console.log('Fetched Orders:', data); // Debug log
        setOrders(data);
        console.log(orders.files);
      } catch (error) {
        console.error('Error fetching orders:', error);
      }
    };

    fetchOrders();
  }, []);
  useEffect(() => {
    // Fetch orders with the search query
    axios.get(`${API_URL}/bobJobs?search=${search}`)
      .then((response) => {
        console.log(response)
        setOrders(response.data);
      })
      .catch((error) => {
        console.error('Error fetching orders:', error);
      });
  }, [search]);


  useEffect(() => {
    const fetchOrderSizes = async () => {
      console.log(orderId)
      if (orderId) {
        try {
          const response = await axios.get(`${API_URL}/fetchorders/${orderId}/sizes`);
          setOrderSizes((prevSizes) => ({
            ...prevSizes,
            [orderId]: response.data, // Store sizes for this order
          }));
          console.log(orderSizes)
        } catch (error) {
          console.error('Error fetching sizes:', error);
        }
      }
    };

    if (openOrder) {
      fetchOrderSizes(); // Fetch sizes only when an order is opened
    }
  }, [openOrder]);

  const handleOrderClick = (orderNumber,order_Id) => {
    setOpenOrder(openOrder === orderNumber ? null : orderNumber);
    setOrderId(order_Id)
    fetchGarmentDetails(order_Id)
  };

  console.log(orderSizes);

 

  
  const handleUpdateOrder = async () => {
    try {
      // API call to update the selected order's specific field
      const response = await axios.put(`${API_URL}/updateOrder/${orderId}`, { [field]: updatedOrder });
      
      alert('Order updated successfully');
      
      // Update the local state with the new order data
      setOrders((prevOrders) =>
        prevOrders.map((order) =>
          order.id === orderId ? { ...order, [field]: updatedOrder } : order
        )
      );

      // Reset the modal state after update
      setShowModal3(false);
      setOrderId('');
      setUpdatedOrder(''); // Clear updated order value
      
    } catch (error) {
      console.error('Error updating order:', error);
    }
  };

  // Function to show modal and set the order and field being edited
  const handleOrder = (id, field) => {
    setShowModal3(true);
    setOrderId(id);
    setField(field); // Track the field being updated
  };

  const deleteOrder = async (id) => {
    const confirmDelete = window.confirm('Are you sure you want to delete this order?');
    if (confirmDelete) {
      try {
        const response = await axios.delete(`${API_URL}/deleteorder/${id}`);
        if (response.status === 200) {
          setOrders(orders.filter(order => order.id !== id));
          alert('Order deleted successfully.');
        }
      } catch (error) {
        console.error('Error deleting order:', error);
        alert('Failed to delete the order.');
      }
    }
  };

  const toggleSortByDueDate = () => {
    setSortByDueDate(!sortByDueDate);
    const sortedOrders = [...orders].sort((a, b) => {
      const dateA = new Date(a.dueDate);
      const dateB = new Date(b.dueDate);
      return sortByDueDate ? dateA - dateB : dateB - dateA;
    });
    setOrders(sortedOrders);
  };



  function cleanFileName(url) {
    // Decode URI components to handle encoded characters like %20 for space
    let decodedUrl = decodeURIComponent(url);
    
    // Extract the filename from the URL if necessary
    let fileName = decodedUrl.split('/').pop(); // Gets the last part after the last slash
    
    // Optional: Remove any unwanted parts from the filename, such as timestamps or identifiers
    // For example, if filenames contain a pattern like '1730410751349-', you might want to remove it:
    fileName = fileName.replace(/^\d+-/, ''); // Removes leading digits followed by a dash
    
    // More cleaning can be applied here depending on the specific filename patterns you have
    
    return fileName;
  }


  const downloadPDF = () => {
    // Prepare data in the format for the table
    const tableData = orders.map((order) => [
      order.id, // Adding the order ID
      order.orderNumber,
      order.clientName,
      
      order.garmentPO,
      
      order.garmentDetails ? 
      order.garmentDetails.split('\n').join(', ') : // Convert to a comma-separated string
      'No Garment details',
      order.jobType
      
    ]);
  
    // Define table columns (headers)
    const tableColumns = [
      'ID', 'Order Number', 'Client Name',  'Garment PO', 'Garment Details','JobType'
    ];
  
    // Initialize jsPDF instance
    const doc = new jsPDF('landscape'); // Use 'landscape' for wide tables
  
    // Set title for the document
    doc.text('Pullsheet Orders', 14, 22);
  
    // Add table with autoTable plugin
    doc.autoTable({
      head: [tableColumns], // Table headers
      body: tableData,      // Table rows (order data)
      startY: 30,           // Position of the table
      theme: 'grid',        // You can also use 'striped', 'plain', etc.
      styles: {
        fontSize: 8,        // Adjust font size to fit more content
      },
      headStyles: {
        fillColor: [22, 160, 133], // Customize header background color
        textColor: 255,           // White text in headers
      }
    });
  
    // Save the PDF with a dynamic filename
    doc.save(`Bob_orders_${moment().format('YYYY-MM-DD')}.pdf`);
  };
  const [garmentDetails, setGarmentDetails] = useState([]);
const fetchGarmentDetails = async (orderId) => {
  try {
      const response = await axios.get(`${API_URL}/orderGarments`, {
          params: { orderId },
      });
      setGarmentDetails(response.data);
  } catch (error) {
      console.error('Error fetching garment details:', error);
  }
};

const fetchGarments = async (poNumber) => {
  console.log(poNumber)
  try {
      const response = await axios.get(`${API_URL}/garments?po=${poNumber}`);

      setGarments(prevGarments => [...prevGarments, ...response.data]);
      console.log(garments)
  } catch (error) {
      console.error('Error fetching garments:', error);
  }
}
const handleAddGarmentsClick = (order) => {
  setOrderId(order.id);
  setGarments([]);
  const garmentPOs = order.garmentPO.split(',');

  garmentPOs.forEach(garmentPO => {
      console.log(garmentPO)
      fetchGarments(garmentPO.trim()); // Use trim() to remove any extra whitespace
  });

  setShowGarmentModal(true);
};



const handleSelectGarment = (garmentId,po, style, description, color, size,available_qty, quantity) => {
    console.log(quantity)
    setSelectedGarments((prev) => {
        // Check if the garment already exists in the selection
        const existingGarment = prev.find((g) => g.garmentId === garmentId);
        if (existingGarment) {
            // Update quantity if the garment is already selected

            return prev.map((g) =>
                g.garmentId === garmentId ? { ...g, quantity } : g
            );
        }

        // Add new garment to selection
        return [
            ...prev,
            { garmentId,po, style, description, color, size, quantity },
        ];
        
    });
    
};

const handleAssignGarments = async () => {
  console.log(selectedGarments);
  if (selectedGarments.length === 0) {
      alert('Please select at least one garment to assign.');
      return;
  }

  try {
      const response = await axios.post(`${API_URL}/assignGarments`, {
          orderId,
          garments: selectedGarments,
      });

      if (response.status === 200) {
          alert('Garments assigned successfully.');

          // **Update the UI immediately**
          fetchGarmentDetails(orderId)

          // Close the modal and clear selected garments
          setShowGarmentModal(false);
          setSelectedGarments([]);

      } else if (response.status === 400) {
          alert(response.data.message);
      }

  } catch (error) {
      console.error('Error assigning garments:', error);
      alert('An error occurred while assigning garments.');
  }
};


const handleDeleteGarment = async (garment) => {
    console.log(garment.order_id)
    if (window.confirm('Are you sure you want to delete this garment?')) {
        try {
            // Send request to backend to update shipping status and delete the garment
            const response = await fetch(`${API_URL}/delete-garment`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({garmentId: garment.garment_id, garmentAssignId: garment.id, quantity: garment.quantity }),
            });

            if (response.ok) {
                alert('Garment deleted successfully.');
                // Remove the deleted garment from the list
                setGarmentDetails((prevDetails) => prevDetails.filter((g) => g.id !== garment.id));
            } else {
                alert('Failed to delete the garment.');
            }
        } catch (error) {
            console.error('Error deleting garment:', error);
            alert('An error occurred while deleting the garment.');
        }
    }
};
  return (
    <div className="container" style={{ marginLeft: 250, paddingTop: 20,marginBottom:70 }}>
      <h2>Bob Jobs</h2>
      <div className="row">
        <div className="col-md-3">
          <input
            type="text"
            className="form-control"
            style={{ width: '300px' }} 
            placeholder="Search orders... 🔍"
            onChange={(e) => setSearch(e.target.value)}
          />
          </div>
          <div className="col-md-2">
            <Button variant="primary" onClick={downloadPDF}>
              <BsDownload style={{ marginRight: '5px' }} /> {/* Add download icon */}
              Download
            </Button>
            </div>
            <div className="col-md-2">
            <Button variant="primary" href="/Invoice">
              Completed BOB Jobs
            </Button>
            </div>
            <div className="col-md-2">
            <Button variant="primary" href="/completedInvoice">
              Completed Invoice
            </Button>
            </div>
            <h4>Total no of Bob Orders : {orders.length}</h4>
      </div>
      
      <table className="table table-striped table-hover">
        <thead className="thead-dark table-header">
          <tr>
            <th scope="col">Order Number</th>
            <th scope="col" className="wide-col">Client Name</th>
            <th scope="col" className="wide-col">Client Phone</th>
            <th scope="col" className="extra-wide-col">Client Gmail</th>
            <th scope="col" className="wide-col">Order Status</th>
            <th scope="col" className="wide-col">Order Method</th>
            <th scope="col" className="wide-col">Job Type</th>
            <th scope="col" className="wide-col">
              Due Date
              <i
                className={`bi bi-sort-${sortByDueDate ? 'down' : 'up'} sort-icon`}
                onClick={toggleSortByDueDate}
              ></i>
            </th>
            
            <th scope="col" className="wide-col">Garment PO</th>
            <th scope="col" className="extra-wide-col">Tracking Number</th>
          </tr>
        </thead>
        <tbody>
          {orders.map((order, index) => {
            console.log('Current Order Status:', order.orderStatus); // Debug log
            return (
              <React.Fragment key={index}>
                <tr onClick={() => handleOrderClick(order.orderNumber,order.id)}>
                  <td className="order-cell">
                    
                    {order.orderNumber}
                  </td>
                  <td>{order.clientName}
                    <>
                    {isAdmin ? (<i 
                          className="bi bi-pencil" 
                          style={{ cursor: 'pointer', marginLeft: '5px' }} 
                          onClick={() => handleOrder(order.id,"clientName")}
                        ></i>) : ''}
                    </>
                  </td>
                  <td>{order.clientPhone}
                    <>
                    {isAdmin ? (<i 
                          className="bi bi-pencil" 
                          style={{ cursor: 'pointer', marginLeft: '5px' }} 
                          onClick={() => handleOrder(order.id,"clientPhone")}
                        ></i>) : ''}
                    </>
                  </td>
                  <td>{order.clientgmail}
                    <>
                    {isAdmin ? (<i 
                          className="bi bi-pencil" 
                          style={{ cursor: 'pointer', marginLeft: '5px' }} 
                          onClick={() => handleOrder(order.id,"clientgmail")}
                        ></i>) : ''}
                    </>
                  </td>
                  <td>
                    {order.orderStatus}
                  </td>

                  <td>{order.orderMethod}</td>
                  <td>{order.jobType}</td>
                  
                  <td>{new Date(order.dueDate).toLocaleDateString('en-US')}
                  
                  </td>
                  
                  

                  <td>{order.garmentPO}
                  <>
                    {isAdmin ? (<i 
                          className="bi bi-pencil" 
                          style={{ cursor: 'pointer', marginLeft: '5px' }} 
                          onClick={() => handleOrder(order.id,"garmentPO")}
                        ></i>) : ''}
                  </>
                  </td>
                  
                  
                  <td>
                    {order.trackingLabel ? (
                      <>
                        {order.trackingLabel}
                        <>
                          {isAdmin ? (<i 
                                className="bi bi-pencil" 
                                style={{ cursor: 'pointer', marginLeft: '5px' }} 
                                onClick={() => handleOrder(order.id,"trackingLabel")}
                              ></i>) : ''}
                        </>
                      </>
                    ) : (
                      <button 
                        className="btn btn-primary" 
                        style={{ cursor: 'pointer' }} 
                        onClick={() => handleOrder(order.id,"trackingLabel")}
                      > 
                        Add num
                      </button>
                    )}
                  </td>

                  {/* <td>
                    <Button
                      variant="primary"
                      onClick={() => handleSizeModalShow(order.orderNumber,order.shippingAddress)}
                    >
                      Add Size
                    </Button>
                  </td> */}

                  <td>
                    <i
                      className="bi bi-trash"
                      style={{ cursor: 'pointer', color: 'red' }}
                      onClick={() => deleteOrder(order.id)}
                    ></i>
                  </td>

                </tr>
                <tr>
                  <td colSpan="12">
                    <Collapse in={openOrder === order.orderNumber}>
                      <div>
                      <div>
                            <p><strong>Garment Details:</strong></p>
                            <Button onClick={() => handleAddGarmentsClick(order)}>Add Garments</Button>
                            <div style={{ paddingLeft: '20px' }}>
                            <p>{order.garmentDetails}</p>
                                  {
                                    
                                      garmentDetails.length > 0 ? (
                                        <div style={{ maxWidth: '800px', border: '1px solid #dee2e6', borderRadius: '8px', overflow: 'hidden', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }}>
                                          <table className="table table-hover" style={{ margin: 0 }}>
                                              <thead style={{ background: 'linear-gradient(to right, #333, #555)', color: 'white', textAlign: 'center' }}>
                                                  <tr>
                                                      <th style={{ padding: '10px', fontWeight: 'bold' }}>Style</th>
                                                      <th style={{ padding: '10px', fontWeight: 'bold' }}>PO</th>
                                                      <th style={{ padding: '10px', fontWeight: 'bold' }}>Description</th>
                                                      <th style={{ padding: '10px', fontWeight: 'bold' }}>Color</th>
                                                      <th style={{ padding: '10px', fontWeight: 'bold' }}>Size</th>
                                                      <th style={{ padding: '10px', fontWeight: 'bold' }}>Quantity</th>
                                                  </tr>
                                              </thead>
                                              <tbody>
                                                  {garmentDetails.map((detail, index) => (
                                                      <tr
                                                          key={index}
                                                          style={{
                                                              backgroundColor: index % 2 === 0 ? '#f8f9fa' : '#ffffff',
                                                              transition: 'background-color 0.3s ease',
                                                          }}
                                                          onMouseEnter={(e) => (e.currentTarget.style.backgroundColor = '#e9ecef')}
                                                          onMouseLeave={(e) => (e.currentTarget.style.backgroundColor = index % 2 === 0 ? '#f8f9fa' : '#ffffff')}
                                                      >
                                                          <td style={{ padding: '8px', textAlign: 'center', width: '10%' }}>{detail.style}</td>
                                                          <td style={{ padding: '8px', textAlign: 'center', width: '10%' }}>{detail.po}</td>
                                                          <td style={{ padding: '8px', width: '40%' }}>{detail.description}</td>
                                                          <td style={{ padding: '8px', textAlign: 'center', width: '15%' }}>{detail.color}</td>
                                                          <td style={{ padding: '8px', textAlign: 'center', width: '10%' }}>{detail.size}</td>
                                                          <td style={{ padding: '8px', textAlign: 'center', width: '10%' }}>{detail.quantity}</td>
                                                          {isAdmin && (
                                                              <td style={{ padding: '8px', textAlign: 'center', width: '10%' }}>
                                                              <button
                                                                  style={{
                                                                      backgroundColor: '#dc3545',
                                                                      color: 'white',
                                                                      border: 'none',
                                                                      borderRadius: '5px',
                                                                      padding: '5px 10px',
                                                                      cursor: 'pointer',
                                                                  }}
                                                                  onClick={() => handleDeleteGarment(detail)}
                                                              >
                                                                  Delete
                                                              </button>
                                                          </td>
                                                          )}
                                                      </tr>
                                                  ))}
                                              </tbody>
                                          </table>
                                      </div>

                                    
                                      ) : (
                                         <></>
                                      )
                                  }
                              </div>


                            {isAdmin && (
                              <i
                                className="bi bi-pencil"
                                style={{ cursor: 'pointer', marginLeft: '5px' }}
                                onClick={() => handleOrder(order.id, "garmentDetails")}
                              ></i>
                            )}
                          </div>
                          <p><strong>Notes:</strong>
                          <div style={{ paddingLeft: '20px' }}>
                              {order.notes ? (
                                <ul>
                                  {order.notes.split('\n').map((detail, index) => (
                                    <li key={index}>{detail}</li> // Display each detail as a list item
                                  ))}
                                </ul>
                              ) : (
                                <p>No notes</p>
                              )}
                          </div>
                        <i 
                          className="bi bi-pencil" 
                          style={{ cursor: 'pointer', marginLeft: '5px' }} 
                          onClick={() => handleOrder(order.id,"notes")}
                        ></i>
                        </p>
                        <p><strong>Team:</strong> {order.team}</p>
                        
                        
                        <p><strong>Shipping Address:</strong> {order.shippingAddress}
                        <>
                          {isAdmin ? (<i 
                                className="bi bi-pencil" 
                                style={{ cursor: 'pointer', marginLeft: '5px' }} 
                                onClick={() => handleOrder(order.id,"shippingAddress")}
                              ></i>) : ''}
                        </>
                        </p>
                        
                        

                    
                        
                       
                        <p><strong>Files Uploaded:</strong></p>
                        <ul style={{ display: 'flex', flexWrap: 'wrap', listStyleType: 'none', padding: 0 }}>
                              {order.files && order.files.length > 0 ? (
                                order.files.filter((file) => file.fileUrl)
                                .map((file, idx) => {
                                  const fileUrl = String(file.fileUrl); // Convert fileUrl to a string
                                  const fileName = cleanFileName(fileUrl); // Clean the filename for display

                                  return (
                                    <li key={idx} style={{
                                      margin: '5px',
                                      width: 'calc(100% / 7 - 10px)', 
                                      display: 'flex',
                                      flexDirection: 'column',
                                      alignItems: 'center',
                                      justifyContent: 'center'
                                    }}>
                                      {/* Display different content based on file type */}
                                      {fileUrl.match(/\.(jpeg|jpg|gif|png)$/i) ? (
                                        <>
                                          <img
                                            src={fileUrl}
                                            alt={`file-${idx}`}
                                            style={{ width: '100%', height: 'auto', maxWidth: '100px', marginBottom: '5px' }}
                                            onClick={() => handleImageClick(fileUrl)}
                                          />
                                          <div>
                                            <a href={fileUrl} download={fileName}>
                                              {fileName} {/* Display the cleaned filename */}
                                            </a>
                                            
                                          </div>
                                        </>
                                      ) : (
                                        <>
                                          <div style={{
                                              display: 'flex', 
                                              alignItems: 'center', 
                                              justifyContent: 'center', 
                                              width: '100px', 
                                              height: '100px', 
                                              border: '1px solid #ddd', 
                                              borderRadius: '5px', 
                                              backgroundColor: '#f8f9fa'
                                            }}
                                          >
                                            <i className="bi bi-file-earmark-text" style={{ fontSize: '24px' }}></i>
                                          </div>
                                          <div>
                                            <a href={fileUrl} download={fileName}>
                                              {fileName} {/* Display the cleaned filename */}
                                            </a>
                                            
                                          </div>
                                        </>
                                      )}
                                    </li>
                                  );
                                })
                              ) : (
                                <li style={{ width: '100%', textAlign: 'center' }}>No files uploaded.</li>
                              )}
                            </ul>



                      </div>
                    </Collapse>
                  </td>
                </tr>
              </React.Fragment>
            );
          })}
        </tbody>
      </table>

      <Modal show={showModal3} onHide={() => setShowModal3(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Enter {field}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group>
            <Form.Label>{field}</Form.Label>
            <Form.Control
              type="text"
              value={updatedOrder}
              onChange={(e) => setUpdatedOrder(e.target.value)}
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal3(false)}>
            Cancel
          </Button>
          <Button variant="primary" onClick={() => handleUpdateOrder()}>
            Submit
          </Button>
        </Modal.Footer>
      </Modal>

      
      {/* Modal for displaying enlarged image */}
      <Modal show={showImageModal} onHide={() => setShowImageModal(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title>Image Preview</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <img src={selectedImage} alt="Enlarged" style={{ width: '100%', height: 'auto' }} />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowImageModal(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Garment Modal */}
                      <Modal
                    show={showGarmentModal}
                    onHide={() => setShowGarmentModal(false)}
                    centered
                    size="xl" // Enlarges the modal
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Select Garments</Modal.Title>
                    </Modal.Header>
                    <Modal.Body style={{ maxHeight: '70vh', overflowY: 'auto', padding: '20px' }}>
                        <Table striped bordered hover responsive>
                            <thead className="table-dark">
                                <tr>
                                    <th>Garment ID</th>
                                    <th>PO</th>
                                    <th>Style</th>
                                    <th>Garment Desc</th>
                                    <th>Color</th>
                                    <th>Size</th>
                                    <th>Available Quantity</th>
                                    <th>Checked In</th>
                                    <th>Assign Quantity</th>
                                </tr>
                            </thead>
                            <tbody>
                                {garments.map((garment) => (
                                    <tr key={garment.id}>
                                        <td>{garment.id}</td>
                                        <td>{garment.customer_po}</td>
                                        <td>{garment.style}</td>
                                        <td>{garment.description}</td>
                                        <td>{garment.color}</td>
                                        <td>{garment.size}</td>
                                        
                                        <td>{garment.qty}</td>
                                        <td>{garment.verified ? 'YES' :'NO'}</td>
                                        <td>
                                            <Form.Control
                                                type="number"
                                                min="1"
                                                max={garment.qty}
                                                onChange={(e) =>
                                                  handleSelectGarment(
                                                      garment.id,
                                                      garment.customer_po,
                                                      garment.style,
                                                      garment.description,
                                                      garment.color,
                                                      garment.size,
                                                      garment.qty,                                                
                                                      parseInt(e.target.value, 10)
                                                  )
                                              }
                                                style={{
                                                    width: '100%',
                                                    padding: '5px 10px',
                                                    borderRadius: '4px',
                                                    border: '1px solid #ced4da',
                                                }}
                                            />
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" 
                        onClick={() => setShowGarmentModal(false)}
                        >
                            Cancel
                        </Button>
                        <Button variant="primary"
                         onClick={handleAssignGarments}
                         >
                            Assign Garments
                        </Button>
                    </Modal.Footer>
                </Modal>

    </div>
  );
};

export default Bob;
