import React, { useState } from "react";


const AddShipmentForm = () => {
  const API_URL = process.env.REACT_APP_API_URL;
  const [formData, setFormData] = useState({
    customer_po: "",
    sales_order: "",
    ship_from: "",
    ship_date: "",
    ship_to_name: "",
    ship_to_address1: "",
    ship_to_address2: "",
    ship_to_city: "",
    ship_to_state: "",
    ship_to_zip: "",
    ship_to_country: "",
    style: "",
    description: "",
    color: "",
    size: "",
    qty: "",
    tracking_number: "",
    ship_via: "",
    verified:false,
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(`${API_URL}/shipment`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(formData),
      });
      const result = await response.json();
      alert(result.message);
    } catch (error) {
      console.error("Error submitting form:", error);
      alert("Submission failed. Check console for details.");
    }
  };

  return (
    <div className="form-container" style={{ marginLeft: 250, paddingTop: 20, marginBottom: 70 }}>
      <h2>Shipping Status</h2>
      <form onSubmit={handleSubmit} className="shipment-form">
        <div className="form-grid">
          <label>Customer PO: <input type="text" name="customer_po" value={formData.customer_po} onChange={handleChange} required /></label>
          <label>Style: <input type="text" name="style" value={formData.style} onChange={handleChange} required /></label>
          <label>Description: <input type="text" name="description" value={formData.description} onChange={handleChange} required /></label>
          <label>Color: <input type="text" name="color" value={formData.color} onChange={handleChange} required /></label>
          <label>Size: <input type="text" name="size" value={formData.size} onChange={handleChange} required /></label>
          <label>Quantity: <input type="number" name="qty" value={formData.qty} onChange={handleChange} required /></label>
          <label>Tracking Number: <input type="text" name="tracking_number" value={formData.tracking_number} onChange={handleChange} /></label>
          <label>Ship Via: <input type="text" name="ship_via" value={formData.ship_via} onChange={handleChange} /></label>
        </div>
        <button type="submit" className="submit-btn">Submit</button>
      </form>
    </div>
  );
};

export default AddShipmentForm;
