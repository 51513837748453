import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Modal, Button, Form } from 'react-bootstrap';

const InventoryPage = () => {
    const [orders, setOrders] = useState([]);
    const [loading, setLoading] = useState(true);
    const [searchTerm, setSearchTerm] = useState('');
    const [searchResults, setSearchResults] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const ordersPerPage = 50;

    const [scannedLabels, setScannedLabels] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [trackingInput, setTrackingInput] = useState('');

    const API_URL = process.env.REACT_APP_API_URL;

    useEffect(() => {
        const fetchOrders = async () => {
            try {
                const response = await axios.get(`${API_URL}/inventory/shipping_status`);
                setOrders(response.data);
                setSearchResults(response.data);
            } catch (error) {
                console.error('Error fetching orders:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchOrders();
    }, []);

    if (loading) {
        return <h1>Loading...</h1>;
    }

    if (orders.length === 0) {
        return <h2>No data found in the shipping_status table.</h2>;
    }

    const columns = Object.keys(orders[0]);
    const excludedColumns = [
        'ship_to_address2', 'ship_to_city', 'ship_to_state',
        'ship_to_zip', 'ship_to_country', 'sub_total', 'freight',
        'handling_fee', 'invoice_total', 'lpn'
    ];
    const displayColumns = columns.filter(column => !excludedColumns.includes(column.toLowerCase()));

    const handleSearch = () => {
        const filtered = orders.filter((order) =>
            displayColumns.some((column) =>
                order[column]?.toString().toLowerCase().includes(searchTerm.toLowerCase())
            )
        );
        setSearchResults(filtered);
        setCurrentPage(1);
    };

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    // Add a scanned label
    const addScannedLabel = () => {
        if (trackingInput.trim()) {
            setScannedLabels((prev) => [...prev, trackingInput.trim()]);
            setTrackingInput(''); // Clear the input after adding
        }
    };

    // Submit scanned labels
    const handleSubmitLabels = async () => {
        if (scannedLabels.length === 0) {
            alert('No labels to submit.');
            return;
        }
    
        try {
            const response = await fetch(`${API_URL}/update-status`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ trackingLabels: scannedLabels }),
            });
    
            if (response.ok) {
                const data = await response.json();
                alert(`Statuses updated successfully! ${data.updatedCount} labels updated.`);
                setScannedLabels([]);
                setShowModal(false);
            } else {
                const errorData = await response.json();
                alert(`Failed to update statuses: ${errorData.message}`);
            }
        } catch (error) {
            console.error('Error updating statuses:', error);
            alert('An error occurred while updating statuses.');
        }
    };
    

    const indexOfLastOrder = currentPage * ordersPerPage;
    const indexOfFirstOrder = indexOfLastOrder - ordersPerPage;
    const currentOrders = searchResults.slice(indexOfFirstOrder, indexOfLastOrder);
    const totalPages = Math.ceil(searchResults.length / ordersPerPage);

    return (
        <div className="container" style={{ marginLeft: 250, paddingTop: 20, marginBottom: 70, fontFamily: 'Arial, sans-serif' }}>
            <h1 style={{ textAlign: 'center', marginBottom: '20px' }}>Shipping Status</h1>

            {/* Search Bar */}
            <div style={{ display: 'flex', marginBottom: '20px' }}>
                <input
                    type="text"
                    placeholder="Search by any field..."
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    style={{
                        flex: 1,
                        padding: '10px',
                        fontSize: '16px',
                        border: '1px solid #ddd',
                        borderRadius: '25px',
                    }}
                />
                <button
                    onClick={handleSearch}
                    style={{
                        padding: '10px 20px',
                        fontSize: '16px',
                        backgroundColor: '#007bff',
                        color: 'white',
                        border: 'none',
                        borderRadius: '5px',
                        cursor: 'pointer',
                    }}
                >
                    Search
                </button>

                <button
                    onClick={() => setShowModal(true)}
                    style={{
                        padding: '10px',
                        fontSize: '16px',
                        backgroundColor: '#28a745',
                        color: 'white',
                        border: '1px solid #ddd',
                        borderRadius: '25px',
                        cursor: 'pointer',
                        marginLeft: '10px',
                        fontWeight: 'bold',
                    }}
                >
                    Scan & Submit
                </button>
            </div>

            {/* Table */}
            <table style={{ width: '100%', borderCollapse: 'collapse' }}>
                <thead>
                    <tr style={{ backgroundColor: '#f4f4f4', textAlign: 'left' }}>
                        {displayColumns.map((column) => (
                            <th key={column} style={{ padding: '10px', border: '1px solid #ddd' }}>
                                {column.replace(/_/g, ' ').toUpperCase()}
                            </th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {currentOrders.map((order, index) => (
                        <tr key={index}>
                            {displayColumns.map((column) => (
                                <td key={column} style={{ padding: '10px', border: '1px solid #ddd' }}>
                                    {order[column] !== null ? order[column].toString() : 'N/A'}
                                </td>
                            ))}
                        </tr>
                    ))}
                </tbody>
            </table>

            {/* Pagination Controls */}
            <div style={{ textAlign: 'center', marginTop: '20px' }}>
                {Array.from({ length: totalPages }, (_, i) => (
                    <button
                        key={i}
                        onClick={() => handlePageChange(i + 1)}
                        style={{
                            margin: '0 5px',
                            padding: '5px 10px',
                            backgroundColor: currentPage === i + 1 ? '#007bff' : '#f4f4f4',
                            color: currentPage === i + 1 ? 'white' : 'black',
                            border: '1px solid #ddd',
                            borderRadius: '5px',
                            cursor: 'pointer',
                        }}
                    >
                        {i + 1}
                    </button>
                ))}
            </div>

            {/* Modal for Scanned Labels */}
            <Modal show={showModal} onHide={() => setShowModal(false)} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Scan Tracking Labels</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group>
                        <Form.Label>Enter or Scan Tracking Label</Form.Label>
                        <Form.Control
                            type="text"
                            value={trackingInput}
                            onChange={(e) => setTrackingInput(e.target.value)}
                            onKeyPress={(e) => e.key === 'Enter' && addScannedLabel()}
                        />
                    </Form.Group>
                    {scannedLabels.length > 0 && (
                        <ul style={{ marginTop: '20px' }}>
                            {scannedLabels.map((label, index) => (
                                <li key={index}>{label}</li>
                            ))}
                        </ul>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowModal(false)}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={handleSubmitLabels}>
                        Submit All
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default InventoryPage;
