import React, { useEffect, useState } from 'react';
import axios from 'axios';

const InstockGarments = () => {
    const [orders, setOrders] = useState([]);
    const [loading, setLoading] = useState(true);
    const [searchTerm, setSearchTerm] = useState('');
    const [searchResults, setSearchResults] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const ordersPerPage = 50;

    const [filteredOrders, setFilteredOrders] = useState([]);
    const [styles, setStyles] = useState([]);
    const [colors, setColors] = useState([]);
    const [sizes, setSizes] = useState([]);

    const [selectedStyle, setSelectedStyle] = useState('');
    const [selectedColor, setSelectedColor] = useState('');
    const [selectedSize, setSelectedSize] = useState('');

    const API_URL = process.env.REACT_APP_API_URL;

    useEffect(() => {
        const fetchOrders = async () => {
            try {
                const response = await axios.get(`${API_URL}/instockgarments`);
                setOrders(response.data);
                setFilteredOrders(response.data);

                // Extract unique styles, colors, and sizes for filtering dropdowns
                const uniqueStyles = [...new Set(response.data.map(order => order.style))].filter(Boolean);
                const uniqueColors = [...new Set(response.data.map(order => order.color))].filter(Boolean);
                const uniqueSizes = [...new Set(response.data.map(order => order.size))].filter(Boolean);

                setStyles(uniqueStyles);
                setColors(uniqueColors);
                setSizes(uniqueSizes);
            } catch (error) {
                console.error('Error fetching orders:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchOrders();
    }, []);

    if (loading) {
        return <h1>Loading...</h1>;
    }

    if (orders.length === 0) {
        return <h2>No data found in the shipping_status table.</h2>;
    }

    // Get all unique column names dynamically from the first row of the table
    const columns = Object.keys(orders[0]);

    // Exclude columns you don't want to display
    const excludedColumns = [
        'ship_to_address2',
        'ship_to_city',
        'ship_to_state',
        'ship_to_zip',
        'ship_to_country',
        'sub_total',
        'freight',
        'handling_fee',
        'invoice_total',
        'lpn'
    ];
    const filterData = () => {
        let filtered = orders;

        if (selectedStyle) {
            filtered = filtered.filter(order => order.style === selectedStyle);
        }
        if (selectedColor) {
            filtered = filtered.filter(order => order.color === selectedColor);
        }
        if (selectedSize) {
            filtered = filtered.filter(order => order.size === selectedSize);
        }

        setFilteredOrders(filtered);
        setCurrentPage(1); // Reset to first page after filtering
    };


    const displayColumns = columns.filter(column => !excludedColumns.includes(column.toLowerCase()));

    // Search function
    const handleSearch = () => {
        const filtered = orders.filter((order) =>
            displayColumns.some((column) =>
                order[column]?.toString().toLowerCase().includes(searchTerm.toLowerCase())
            )
        );
        setFilteredOrders(filtered);
        setCurrentPage(1); // Reset to the first page after a search
    };

    // Delete function
    const handleDelete = async (id) => {
        const confirmDelete = window.confirm('Are you sure you want to delete this garment?');
        if (!confirmDelete) return;

        try {
            await axios.delete(`${API_URL}/instockgarments/${id}`);
            const updatedOrders = orders.filter(order => order.id !== id);
            setOrders(updatedOrders);
            setFilteredOrders(updatedOrders);
        } catch (error) {
            console.error('Error deleting garment:', error);
        }
    };

    // Pagination calculations
    const indexOfLastOrder = currentPage * ordersPerPage;
    const indexOfFirstOrder = indexOfLastOrder - ordersPerPage;
    
    const currentOrders = filteredOrders.slice(indexOfFirstOrder, indexOfLastOrder);
    const totalPages = Math.ceil(filteredOrders.length / ordersPerPage);

    // Handle page change
    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    return (
        <div className="container" style={{ marginLeft: 250, paddingTop: 20, marginBottom: 70, fontFamily: 'Arial, sans-serif' }}>
            <h1 style={{ textAlign: 'center', marginBottom: '20px' }}>InStock Garments</h1>

            {/* Search Bar */}
            <div style={{ display: 'flex', marginBottom: '20px' }}>
                <input
                    type="text"
                    placeholder="Search by any field..."
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    style={{
                        flex: 1,
                        padding: '10px',
                        fontSize: '16px',
                        border: '1px solid #ddd',
                        borderRadius: '25px',
                    }}
                />
                <button
                    onClick={handleSearch}
                    style={{
                        padding: '10px 20px',
                        fontSize: '16px',
                        backgroundColor: '#007bff',
                        color: 'white',
                        border: 'none',
                        borderRadius: '5px',
                        cursor: 'pointer',
                    }}
                >
                    Search
                </button>
            </div>
             {/* Filter Controls */}
             <div style={{ display: 'flex', gap: '10px', marginBottom: '20px' }}>
                <select value={selectedStyle} onChange={(e) => setSelectedStyle(e.target.value)} style={dropdownStyle}>
                    <option value="">All Styles</option>
                    {styles.map((style, index) => (
                        <option key={index} value={style}>{style}</option>
                    ))}
                </select>

                <select value={selectedColor} onChange={(e) => setSelectedColor(e.target.value)} style={dropdownStyle}>
                    <option value="">All Colors</option>
                    {colors.map((color, index) => (
                        <option key={index} value={color}>{color}</option>
                    ))}
                </select>

                <select value={selectedSize} onChange={(e) => setSelectedSize(e.target.value)} style={dropdownStyle}>
                    <option value="">All Sizes</option>
                    {sizes.map((size, index) => (
                        <option key={index} value={size}>{size}</option>
                    ))}
                </select>

                <button onClick={filterData} style={buttonStyle}>Filter</button>
            </div>

            {/* Table */}
            <table style={{ width: '100%', borderCollapse: 'collapse' }}>
                <thead>
                    <tr style={{ backgroundColor: '#f4f4f4', textAlign: 'left' }}>
                        {displayColumns.map((column) => (
                            <th key={column} style={{ padding: '10px', border: '1px solid #ddd' }}>
                                {column.replace(/_/g, ' ').toUpperCase()}
                            </th>
                        ))}
                        <th style={{ padding: '10px', border: '1px solid #ddd' }}>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {currentOrders.map((order, index) => (
                        <tr key={index}>
                            {displayColumns.map((column) => (
                                <td key={column} style={{ padding: '10px', border: '1px solid #ddd' }}>
                                    {order[column] !== null ? order[column].toString() : 'N/A'}
                                </td>
                            ))}
                            <td style={{ padding: '10px', border: '1px solid #ddd', textAlign: 'center' }}>
                                <button
                                    onClick={() => handleDelete(order.id)}
                                    style={{
                                        padding: '5px 10px',
                                        fontSize: '14px',
                                        backgroundColor: 'red',
                                        color: 'white',
                                        border: 'none',
                                        borderRadius: '5px',
                                        cursor: 'pointer',
                                    }}
                                >
                                    Delete
                                </button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>

            {/* Pagination Controls */}
            <div style={{ textAlign: 'center', marginTop: '20px' }}>
                {Array.from({ length: totalPages }, (_, i) => (
                    <button
                        key={i}
                        onClick={() => handlePageChange(i + 1)}
                        style={{
                            margin: '0 5px',
                            padding: '5px 10px',
                            backgroundColor: currentPage === i + 1 ? '#007bff' : '#f4f4f4',
                            color: currentPage === i + 1 ? 'white' : 'black',
                            border: '1px solid #ddd',
                            borderRadius: '5px',
                            cursor: 'pointer',
                        }}
                    >
                        {i + 1}
                    </button>
                ))}
            </div>
        </div>
    );
};

const dropdownStyle = {
    padding: '10px',
    fontSize: '16px',
    border: '1px solid #ddd',
    borderRadius: '5px',
    width: '150px'
};

const buttonStyle = {
    padding: '10px 20px',
    fontSize: '16px',
    backgroundColor: '#007bff',
    color: 'white',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer'
};
export default InstockGarments;
